<template>
  <div class="page-password-reset">
    <h2>{{ $t('password.title') }}</h2>
    <pro-form
      label-width="150px"
      label-suffix=":"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitButtonProps: {
          saveLoading
        },
        submitText: $t('password.add-submit-text'),
        resetText: $t('password.add-cancel-text')
      }"
      @submit="submit"
      @reset="reset"
    ></pro-form>
  </div>
</template>

<script>
export default {
  data() {
    const t = this.$t.bind(this)
    return {
      redirect: this.$route.query.redirect,
      sendLoading: false,
      saveLoading: false,
      countdown: 0,
      model: {
        emailAddress: '',
        code: '',
        password: '',
        confirmPassword: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: t('password.email'),
            prop: 'emailAddress'
          },
          style: {
            width: '360px'
          },
          attributes: {
            placeholder: t('password.placeholder-input')
          }
        },
        {
          formItemProps: {
            label: t('password.captcha'),
            prop: 'code'
          },
          render: (h, prop, field, model) => {
            return (
              <div>
                <el-input
                  style={{ width: '360px' }}
                  v-model={model[prop]}
                  placeholder={t('password.placeholder-input')}
                ></el-input>
                <el-button
                  onClick={this.sendCode}
                  style={{ marginLeft: '20px' }}
                  type="primary"
                  loading={this.sendLoading}
                  disabled={this.countdown !== 0}
                >
                  {t('password.captcha-btn')}
                  {this.countdown === 0 ? '' : ` (${this.countdown})`}
                </el-button>
              </div>
            )
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: t('password.password'),
            prop: 'password'
          },
          style: {
            width: '360px'
          },
          attributes: {
            placeholder: t('password.placeholder-input')
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: t('password.confirm-password'),
            prop: 'confirmPassword'
          },
          style: {
            width: '360px'
          },
          attributes: {
            placeholder: t('password.placeholder-input')
          }
        }
      ],
      rules: {
        emailAddress: [
          {
            required: true,
            message: t('password.placeholder-input'),
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: t('password.placeholder-input'),
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            message: t('password.placeholder-input'),
            trigger: 'change'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: t('password.placeholder-input'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    notMatch() {
      if (!this.model.password || !this.model.confirmPassword) {
        return false
      }
      return this.model.password !== this.model.confirmPassword
    }
  },
  unMounted() {
    if (this.timer) clearTimeout(this.timer)
  },
  methods: {
    async sendCode() {
      if (!this.model.emailAddress) {
        this.$message.warning('请输入邮箱')
        return
      }
      this.sendLoading = true
      const [err] = await this.$serve.email.sendEmail({
        data: {
          emailAddress: this.model.emailAddress
        }
      })
      this.sendLoading = false
      if (err) return
      this.$message.success('验证码发送成功')
      this.countdown = 60
      this.runCountdown()
    },
    runCountdown() {
      if (this.timer) clearTimeout(this.timer)
      if (this.countdown === 0) {
        return
      }
      this.timer = setTimeout(() => {
        this.countdown--
        this.runCountdown()
      }, 1000)
    },
    async submit() {
      if (this.notMatch) {
        this.$message.error('两次密码不一致')
        return
      }
      this.saveLoading = true
      const [err] = await this.$serve.email.resetPassword({
        data: {
          code: this.model.code,
          emailAddress: this.model.emailAddress,
          password: this.model.password
        }
      })
      this.saveLoading = false
      if (err) return
      this.$message({
        type: 'success',
        message: '密码修改成功',
        onClose: () => {
          if (this.redirect) {
            location.href = this.redirect
          }
        }
      })
    },
    reset() {
      if (this.redirect) {
        location.href = this.redirect
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-password-reset {
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  width: 680px;
  margin: 50px auto;
}
</style>
