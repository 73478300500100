var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-password-reset"},[_c('h2',[_vm._v(_vm._s(_vm.$t('password.title')))]),_c('pro-form',{attrs:{"label-width":"150px","label-suffix":":","model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
      submitButtonProps: {
        saveLoading: _vm.saveLoading
      },
      submitText: _vm.$t('password.add-submit-text'),
      resetText: _vm.$t('password.add-cancel-text')
    }},on:{"submit":_vm.submit,"reset":_vm.reset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }